// import Footer from "../components/Footer";
// import Header from "../components/Header";
// import Hero from "../components/Hero";
// import SearchBar from "../components/SearchBar";
import HomeHero from "../components/Home/HomeHero";
import Footer from "../landingPage/components/Footer";
import Hero from "../landingPage/components/Hero";
import Navbar from "../landingPage/components/Navbar";
import AppFooter from "./AppFooter";
import AppNavbar from "./AppNavBar";


interface Props {
  children: React.ReactNode;
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <AppNavbar />
      <HomeHero />
      <div className="container mx-auto py-10 flex-1">{children}</div>
      <AppFooter />
    </>

  );
};

export default Layout;
