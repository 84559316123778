import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai';

const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);

  const toggleNav = () => setNavOpen(!navOpen);

  return (
    <header className="fixed top-0 w-full bg-[#0d2528] shadow-md z-50">
      <div className="container mx-auto flex justify-evenly items-center p-4">
        <img src='images/logo_2.png' height={150} width={175}></img>
        <nav className="hidden md:flex space-x-6">
          {['About', 'Facilities', 'Gallery', 'Testimonials', 'Contact'].map((section) => (
            <Link
              key={section}
              to={section.toLowerCase()}
              smooth={true}
              duration={500}
              offset={-70} // Offset for sticky header
              className="text-white hover:text-[#EEBC1D] font-medium cursor-pointer"
            >
              {section}
            </Link>
          ))}
        </nav>

        {/* Mobile Menu Icon */}
        <div className="md:hidden flex items-center">
          <button onClick={toggleNav} aria-label="Toggle navigation">
            {navOpen ? <AiOutlineClose className="text-2xl text-[#EEBC1D]" /> : <AiOutlineMenu className="text-2xl text-[#EEBC1D]" />}
          </button>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      {navOpen && (
        <nav className="md:hidden bg-white shadow-md absolute top-16 left-0 w-full">
          <ul className="flex flex-col space-y-4 p-4">
            {['About', 'Facilities', 'Gallery', 'Testimonials', 'Contact'].map((section) => (
              <li key={section}>
                <Link
                  to={section.toLowerCase()}
                  smooth={true}
                  duration={500}
                  offset={-70} // Offset for sticky header
                  className="block text-gray-600 hover:text-[#EEBC1D] font-medium"
                  onClick={() => setNavOpen(false)}
                >
                  {section}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </header>
  );
};

export default Navbar;
