import React from 'react';

const images = [
  { src: 'images/room_1.jpeg', alt: 'Room' },
  { src: 'images/room_2.jpeg', alt: 'Room' },
  { src: 'images/room_3.jpeg', alt: 'Room' },
  { src: 'images/meeting_area.jpeg', alt: 'Meeting Area' },
  { src: 'images/gym.jpeg', alt: 'Gym' },
  { src: 'images/playing_area.jpeg', alt: 'Playing Area' },
  { src: 'images/pool.jpeg', alt: 'Swimming Pool' },
  { src: 'images/cafeteria.jpeg', alt: 'Cafeteria' },
  { src: 'images/kitchen.jpeg', alt: 'Kitchen' },
];

const Gallery = () => {

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="py-20 px-8 bg-gray-100">
      <h2 className="text-4xl font-bold text-center text-gray-800 mb-12">Hotel Gallery</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6">
        {images.map((image, index) => (
          <div key={index} className="relative group overflow-hidden rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 ease-in-out">
            <img
              src={image.src}
              alt={image.alt}
              className="w-full h-80 object-cover group-hover:scale-110 transition-transform duration-500 ease-in-out"
            />
            <div className="absolute inset-0 bg-black bg-opacity-30 group-hover:bg-opacity-50 transition-all duration-300 ease-in-out">
              <div className="absolute bottom-4 left-4 text-white font-semibold text-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                {image.alt}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='flex justify-center items-center mt-4'>
        <button
          onClick={scrollToContact}
          className="px-6 py-3 mt-4 border-2 bg-[#EEBC1D] hover:bg-white border-[#EEBC1D] text-black text-lg font-semibold rounded-md transition duration-300 hover:bg-[#EEBC1D] hover:text-black"
        >
          Your Perfect Stay Awaits – Reserve Today
        </button>
      </div>

    </section>
  );
};

export default Gallery;
