import React from 'react';
import './App.css';
import LandingPage from './landingPage/LandingPage';
import './css/style.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Layout from './layouts/Layout';
import Home from './pages/Home';
// import LoginPage from './pages/LoginPage';

function App() {
  return (
    <Routes>
      <Route path="/quickbook" element={<LandingPage />} />
      <Route path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
