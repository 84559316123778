// import { useQuery } from "react-query";
// import * as apiClient from "../api-client";
import GetInTouch from "../components/Home/GetInTouch";
import PopularRoom from "../components/Home/PopularRoom";
import About from "../landingPage/components/About";
import CTA from "../landingPage/components/CTA";
import Testimonials from "../landingPage/components/Testimonials";
import { primaryColor, secondaryColor } from "../theme/theme";

const Home = () => {

  return (
    <div className="space-y-3 ">
      <div className="flex items-center justify-center h-32 bg-gray-100 p-4 md:h-40 lg:h-48">
        <h2 className="text-xl md:text-2xl lg:text-4xl font-semibold text-center text-gray-800">
          Call us for booking: <a href="tel:+919151999569" className="text-blue-600 underline">+91 915 199 9569</a>
        </h2>
      </div>
      <About  isFromLandingPage={false}/>
      <PopularRoom />
      <Testimonials />
      <GetInTouch />
      <CTA isFromLandingPage={false}/>
    </div>
  );
};

export default Home;
