import React from 'react'
import { hotelManagerURL } from '../../constant';

interface CTAProps {
  isFromLandingPage: boolean;
}

const CTA = (props: CTAProps) => {
  return (
    <section className="relative w-full h-[300px] bg-cover bg-center" style={{ backgroundImage: 'url(images/cta_bg.jpg)' }}>
      <div className="absolute inset-0 bg-black bg-opacity-40"></div>
      <div className="relative z-10 flex flex-col sm:flex-row justify-center sm:justify-between items-center h-full text-center text-white px-4">
        <h2 className="text-3xl sm:text-4xl lg:text-4xl font-bold mb-6 sm:mb-0 sm:w-1/2">
          A Best Place To Stay. Reserve Now!
        </h2>
        <a 
          href={props.isFromLandingPage ? "#contact" : hotelManagerURL}
          className="px-8 py-3 bg-[#EEBC1D] hover:bg-[#EEBC1D] text-black text-lg font-semibold rounded-full transition-all duration-300 ease-in-out sm:w-auto w-full mt-4 sm:mt-0"
        >
          Reserve Now
        </a>
      </div>
    </section>
  )
}

export default CTA
