import React, { useState } from 'react'
import { FaLock, FaUser } from 'react-icons/fa';
import { Link } from 'react-scroll';
import { hotelManagerURL } from '../constant';

const AppNavbar = () => {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    <header className="bg-[#0d2528] shadow-md fixed top-0 w-full z-50">
      <div className="max-w-7xl mx-auto p-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              <img src='images/logo_2.png' height={150} width={175}></img>
            </Link>
          </div>

          <div className="hidden md:flex space-x-8 items-center">
            <Link to="/" className="text-white hover:text-[#EEBC1D] font-medium cursor-pointer">Home</Link>
            <a href={hotelManagerURL} className="text-white hover:text-[#EEBC1D] font-medium cursor-pointer">Rooms</a>
            <Link to="/about" className="text-white hover:text-[#EEBC1D] font-medium cursor-pointer">About Us</Link>
            <Link to="/contact" className="text-white hover:text-[#EEBC1D] font-medium cursor-pointer">Contact Us</Link>
            {/* {isLoggedIn ? (
              <Link
                to="/profile"
                className="flex items-center space-x-2 bg-[#EEBC1D] text-[#0d2528] py-2 px-4 rounded-full shadow-md hover:bg-yellow-600 transition cursor-pointer"
              >
                <div className='flex items-center gap-2'>
                  <FaUser size={16} />
                  <span className="font-medium">Profile</span>
                </div>
              </Link>) : (
              <Link
                to="/login"
                className="border border-[#EEBC1D] text-[#EEBC1D] font-medium py-2 px-4 rounded-lg hover:bg-[#EEBC1D] hover:text-[#0d2528] transition cursor-pointer"
              >
                <div className='flex items-center gap-2'>
                  <FaLock size={16} />
                  <span className="font-medium">Login</span>
                </div>

              </Link>)} */}
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-[#EEBC1D] font-medium cursor-pointer"
            >
              <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"} />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden">
          <Link to="/" className="block px-4 py-2 text-white hover:text-[#EEBC1D] font-medium cursor-pointer">Home</Link>
          <a href={hotelManagerURL} className="block px-4 py-2 text-white hover:text-[#EEBC1D] font-medium cursor-pointer">Rooms</a>
          <Link to="/about" className="block px-4 py-2 text-white hover:text-[#EEBC1D] font-medium cursor-pointer">About Us</Link>
          <Link to="/contact" className="block px-4 py-2 text-white hover:text-[#EEBC1D] font-medium cursor-pointer">Contact Us</Link>
          {/* {isLoggedIn ? (
            <Link
              to="/profile"
              className="flex w-fit items-center mx-4 my-2 bg-[#EEBC1D] text-[#0d2528] py-2 px-4 rounded-full shadow-md hover:bg-yellow-600 transition"
            >
              <FaUser className="mr-2" size={16} />
              Profile
            </Link>) : (
            <Link
              to="/login"
              className="flex w-fit items-center mx-4 my-2 border border-[#EEBC1D] text-[#EEBC1D] py-2 px-4 rounded-full hover:bg-[#EEBC1D] hover:text-[#0d2528] transition"
            >
              <FaLock className="mr-2" size={16} />
              Login
            </Link>)} */}
        </div>
      )}
    </header>
  );
}

export default AppNavbar;