import { FloatingWhatsApp } from "react-floating-whatsapp";
import About from "./components/About";
import Contact from "./components/Contact";
import CTA from "./components/CTA";
import Facilities from "./components/Facilities";
import Footer from "./components/Footer";
import Gallery from "./components/Gallary";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Testimonials from "./components/Testimonials";

const LandingPage = () => {
  return (
    <>
      <Navbar />
      <div id="hero"><Hero /></div>
      <div id="about"><About isFromLandingPage={true}/></div>
      <div id="facilities"><Facilities /></div>
      <div id="gallery"><Gallery /></div>
      <div id="testimonials"><Testimonials /></div>
      <div id="contact"><Contact /></div>
      <CTA isFromLandingPage={true}/>
      <Footer />
      <FloatingWhatsApp phoneNumber="9151999569" accountName="Evanthe Hotels" avatar="images/favicon.png" />
    </>
  );
};

export default LandingPage;
