import React from 'react'

const GetInTouch = () => {
  return (
    <section className="bg-[#f3f4f6] py-12">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl md:text-4xl font-bold text-[#0d2528] text-center mb-8">
          Get In Touch
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <div className="mb-6">
              <h3 className="text-2xl font-bold text-[#0d2528] mb-2">Mailing Address</h3>
              <p className="text-gray-700">
                B2 - 830, Dlf My Pad, Vibhuti Khand,<br />
                Gomti Nagar, Lucknow 226010<br />
                Uttar Pradesh
              </p>
            </div>

            <div className="mb-6">
              <h3 className="text-2xl font-bold text-[#0d2528] mb-2">Email Address</h3>
              <p className="text-gray-700">evanthehotels@gmail.com</p>
              <p className="text-gray-700">booking.evanthehotels@gmail.com</p>
            </div>

            <div>
              <h3 className="text-2xl font-bold text-[#0d2528] mb-2">Phone Number</h3>
              <p className="text-gray-700">+91 91519 99569</p>
            </div>
          </div>

          <div className="flex justify-center">
            <img
              src="/images/hero_3.jpeg"
              alt="Contact Us"
              className="rounded-lg shadow-md w-full h-auto object-cover"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default GetInTouch