import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyDJCTIuOj2rBNB7PG6JL_y4UEYP3f69Vog",
  authDomain: "evanthe-hotels.firebaseapp.com",
  projectId: "evanthe-hotels",
  storageBucket: "evanthe-hotels.firebasestorage.app",
  messagingSenderId: "242873172841",
  appId: "1:242873172841:web:0959ec0489cb0cda10b3f8",
  measurementId: "G-BG95F40B2K"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export { auth, db, provider };