import React from 'react'
import { secondaryColor } from '../../theme/theme'
import { hotelManagerURL } from '../../constant'

const HomeHero = () => {
  return (
    <section
      className="relative bg-[url(https://images.unsplash.com/photo-1604014237800-1c9102c219da?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)] bg-cover bg-center bg-no-repeat"
    >
      <div
        className="absolute inset-0 bg-black opacity-50" //bg-white/75 sm:bg-transparent sm:from-white/95 sm:to-white/25 ltr:sm:bg-gradient-to-r rtl:sm:bg-gradient-to-l
      ></div>

      <div
        className="relative mx-auto max-w-screen-xl px-4 py-32 sm:px-6 lg:flex lg:h-screen md:items-center lg:px-8"
      >
        <div className="lg:max-w-xl text-center md:text-center lg:text-left">
          <h1 className="text-3xl font-extrabold sm:text-5xl text-white">
            Book Rooms directly

            <strong className={`block font-extrabold text-[${secondaryColor}]`}> & get 10% off. </strong>
          </h1>

          <p className="mt-4 lg:max-w-lg text-white sm:text-xl/relaxed">
            Exclusive discounts when you skip the middleman. Your perfect stay, just one click away!
          </p>

          <div className="mt-8 flex flex-wrap gap-4 text-center md:justify-center lg:justify-start">
            <a
              href={hotelManagerURL}
              className={`block w-full rounded bg-[${secondaryColor}] md:px-12 sm:px-4 py-3 text-black text-lg font-bold shadow focus:outline-none focus:ring active:bg-rose-500 sm:w-auto`}
            >
              Book Now
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HomeHero