import React from 'react';
import { hotelManagerURL } from '../../constant';

interface AboutProps {
  isFromLandingPage: boolean;
}

const About = (props: AboutProps) => {

  const scrollToContact = () => {
    if (props.isFromLandingPage) {
      const contactSection = document.getElementById('contact');
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.location.href = hotelManagerURL;
    }
  };

  return (
    <div className="flex flex-col md:flex-row items-center justify-center p-10 md:p-20 space-y-8 md:space-y-0 md:space-x-12">
      {/* Left Side - Image Grid */}
      <div className="grid grid-cols-2 gap-4 md:w-1/2">
        <img
          src="images/welcome_1.jpeg"
          alt="Room 1"
          className="w-full h-100 object-fit rounded-md"
        />
        <img
          src="images/welcome_2.jpeg"
          alt="Room 2"
          className="w-full h-100 object-fit rounded-md"
        />
        <img
          src="images/welcome_3.jpeg"
          alt="Room 3"
          className="w-full h-60 object-fit rounded-md col-span-2"
        />
      </div>

      {/* Right Side - Text Content */}
      <div className="md:w-1/2 space-y-4">
        <h2 className="text-4xl font-bold">Welcome to Our Oasis of Comfort and Luxury</h2>
        <p className="text-gray-600 leading-relaxed">
          Discover a tranquil retreat where comfort meets elegance. Our carefully designed spaces, exceptional service, and luxurious amenities provide you with a sanctuary to unwind, recharge, and feel at home. From the moment you arrive, you’ll experience the dedication to excellence that defines every detail of your stay.
        </p>
        <p className="text-gray-600 leading-relaxed">
          Whether you’re here for leisure or business, our aim is to exceed your expectations with personalized experiences and memorable moments. Indulge in our top-tier facilities, savor delicious cuisine, and let us make your stay truly unforgettable. Welcome to the ultimate blend of relaxation and refinement.
        </p>
        <button onClick={scrollToContact} className="px-6 py-3 border-2 bg-[#EEBC1D] border-[#EEBC1D] hover:bg-white text-black text-lg font-semibold rounded-md transition duration-300">
          Book Your Stay with Us
        </button>
      </div>
    </div>
  );
};

export default About;
