import React from 'react';
import { FaBed, FaConciergeBell, FaUtensils, FaWifi, FaParking, FaPumpSoap, FaLock, FaTshirt, FaTaxi, FaUserMd, FaBriefcase } from 'react-icons/fa';

const facilities = [
  {
    title: "Comfort and Luxury AC Rooms",
    description: "Experience unmatched comfort in our well-furnished, air-conditioned rooms, designed to offer you a luxurious and relaxing stay.",
    icon: <FaBed />
  },
  {
    title: "24 Hour Room Service",
    description: "Enjoy around-the-clock room service with a wide range of food and amenities, available whenever you need them.",
    icon: <FaConciergeBell />
  },
  {
    title: "Room Dining Available",
    description: "Dine in the comfort of your room with our convenient room dining service, offering a variety of meals and beverages.",
    icon: <FaUtensils />
  },
  {
    title: "Free Wifi",
    description: "Stay connected with our high-speed, complimentary WiFi, available throughout the premises for all guests.",
    icon: <FaWifi />
  },
  {
    title: "Free Parking",
    description: "We provide secure, free parking facilities for our guests, ensuring your vehicle's safety and convenience.",
    icon: <FaParking />
  },
  {
    title: "Sanitised Rooms",
    description: "Your safety is our priority. All rooms are thoroughly sanitized to maintain the highest standards of cleanliness and hygiene.",
    icon: <FaPumpSoap />
  },
  {
    title: "24 x 7 Security & Power Backup",
    description: "Rest easy with our round-the-clock security and reliable power backup, ensuring a safe and comfortable stay.",
    icon: <FaLock />
  },
  {
    title: "Laundry Service",
    description: "Enjoy the convenience of our efficient laundry service, keeping your wardrobe fresh and ready for any occasion.",
    icon: <FaTshirt />
  },
  {
    title: "Taxi Service",
    description: "Explore the area with ease using our trusted taxi service, available to all guests for convenient local travel.",
    icon: <FaTaxi />
  },
  {
    title: "24 Hours Doctor on Call",
    description: "For any health concerns, we have a doctor on call 24/7, ensuring your peace of mind throughout your stay.",
    icon: <FaUserMd />
  },
  {
    title: "Meeting Facilities",
    description: "Host productive meetings in our fully-equipped meeting rooms, ideal for business gatherings and conferences.",
    icon: <FaBriefcase />
  },
];

const Facilities = () => {
  return (
    <section className="py-20 px-8 bg-cover bg-center relative" style={{ backgroundImage: 'url(images/facility_bg.jpeg)' }}>
      <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay for text visibility */}
      <div className="relative z-10">
        <h2 className="text-4xl font-bold text-center text-white mb-12">Our Facilities</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {facilities.map((facility, index) => (
            <div
              key={index}
              className="p-8 rounded-lg shadow-lg border border-opacity-70 text-center"
              style={{
                backgroundColor: 'rgba(0, 0, 0, 0.55)', // Transparent background
                borderColor: 'rgba(0, 0, 0, 0.5)' // Dark border
              }}
            >
              <div className="flex items-center justify-center mb-4">
                <span className="text-yellow-400 text-2xl">{facility.icon}</span>
                <h3 className="text-xl font-bold ml-4 text-white">{facility.title}</h3>
              </div>
              <p className="text-gray-300">{facility.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Facilities;
