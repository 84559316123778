import React from 'react'
import { secondaryColor } from '../../theme/theme';
import { hotelManagerURL } from '../../constant';

const PopularRoom = () => {

  const rooms = [
    {
      id: 1,
      title: 'Dolphin Rooms by Evanthe',
      description: 'Welcome to Dolphin Stays by Evanthe, your serene retreat amidst the vibrant city of Lucknow. Nestled in Gomti Nagar, these thoughtfully curated rooms combine sophistication and convenience. Enjoy spacious accommodations with premium furnishings, scenic city views, and tailored services. Whether it’s a family getaway or a business trip, Dolphin Stays ensures an unforgettable experience.',
      image: '/images/room_1.jpeg',
    },
    {
      id: 2,
      title: 'Deluxe Studio Rooms in Gomti Nagar',
      description: 'Experience luxury and comfort at its finest in our Deluxe Studio rooms located in the heart of Gomti Nagar. Designed for both leisure and business travelers, these rooms feature elegant interiors, plush bedding, and modern amenities. Relax and rejuvenate with a peaceful ambiance, high-speed Wi-Fi, smart TV, and 24/7 room service. Perfect for a luxurious stay with a touch of homely charm.',
      image: '/images/room_3.jpeg',
    },
  ];

  return (
    <section className="py-20 px-8 bg-cover bg-center relative" style={{ backgroundImage: 'url(images/facility_bg.jpeg)' }}>
      <div className="absolute inset-0 bg-black opacity-50"></div>
      <div className="relative z-10">
        <div className=" max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl md:text-4xl font-extrabold text-white text-center mb-8">
            Explore Our Rooms
          </h2>
          <div className="flex justify-center items-center">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {rooms.map((room) => (
                <div
                  key={room.id}
                  className="w-80 h-[500px] bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 flex flex-col"
                  style={{
                    backgroundColor: 'rgba(0, 0, 0, 0.55)',
                    borderColor: 'rgba(0, 0, 0, 0.5)'
                  }}
                >
                  <a href={hotelManagerURL}>
                    <div className="h-48 overflow-hidden rounded-t-lg">
                      <img
                        className="object-cover w-full h-full"
                        src={room.image}
                        alt={room.title}
                      />
                    </div>
                  </a>
                  <div className="p-5 flex-grow flex flex-col justify-between">
                    <div>
                      <a href={hotelManagerURL}>
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                          {room.title}
                        </h5>
                      </a>
                      <p className="text-gray-700 dark:text-gray-400 text-sm overflow-hidden">
                        {room.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2">
            {rooms.map((room) => (
              <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.55)',
                borderColor: 'rgba(0, 0, 0, 0.5)'
              }}>
                <a href="https://reservations.hotel-spider.com/03m674de2f0cdf6e">
                  <img className="rounded-t-lg" src={room.image} alt="" />
                </a>
                <div className="p-5">
                  <a href="https://reservations.hotel-spider.com/03m674de2f0cdf6e">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{room.title}</h5>
                  </a>
                  <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">{room.description}</p>
                </div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  )
}

export default PopularRoom