import React, { useState } from 'react';
import { db } from '../../firebase';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
const Contact = () => {
  const [ formData, setFormData ] = useState({
    name: '',
    phone: '',
    email: '',
    checkIn: null,
    checkOut: null,
    numberOfPersons: '',
  });
  const [ errorMessage, setErrorMessage ] = useState('');
  const [ successMessage, setSuccessMessage ] = useState('');

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [ name ]: value,
    }));
  };

  const handleDateChange = (date: any, field: any) => {
    setFormData((prevData) => ({
      ...prevData,
      [ field ]: date,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (formData.checkIn && formData.checkOut && new Date(formData.checkOut) < new Date(formData.checkIn)) {
      setErrorMessage('Check-out date cannot be before check-in date.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'leads'), {
        ...formData,
        createdOn: serverTimestamp(),
      });
      setSuccessMessage("Your request has been submitted, We will contact you as soon as possible.");

      // Clear form
      setFormData({
        name: '',
        phone: '',
        email: '',
        checkIn: null,
        checkOut: null,
        numberOfPersons: '',
      });

    } catch (error) {
      setErrorMessage('Failed to submit. Please try again later.');
    }

    setErrorMessage('');
    console.log(formData);
  };

  return (
    <section className="py-16 px-4 md:px-16">
      <div className="max-w-screen-lg mx-auto">
        <h2 className="text-3xl font-bold mb-8 text-center">Book Now</h2>
        <p className="text-lg text-gray-600 mb-12 text-center">
          Book now to secure your spot and enjoy an exceptional experience. It’s quick, easy, and guaranteed to meet your needs!        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
          <div>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label htmlFor="name" className="block text-gray-700 font-bold mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="phone" className="block text-gray-700 font-bold mb-2">
                  Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 font-bold mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              <div className="mb-4 flex flex-col md:flex-row space-y-4 lg:space-y-0 md:space-x-4">
                <div className="w-full md:w-1/2">
                  <label htmlFor="checkIn" className="block text-gray-700 font-bold mb-2">
                    Check-in
                  </label>
                  <DatePicker
                    onChange={(date) => handleDateChange(date, 'checkIn')}
                    dateFormat="dd-MM-yyyy hh:mm a"
                    selected={formData.checkIn}
                    placeholderText="dd-MM-yyyy HH:mm"
                    showTimeSelect
                    timeFormat="hh:mm a"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
                <div className="w-full md:w-1/2">
                  <label htmlFor="checkOut" className="block text-gray-700 font-bold mb-2">
                    Check-out
                  </label>
                  <DatePicker
                    onChange={(date) => handleDateChange(date, 'checkOut')}
                    selected={formData.checkOut}
                    dateFormat="dd-MM-yyyy hh:mm a"
                    placeholderText="dd-MM-yyyy HH:mm"
                    showTimeSelect
                    timeFormat="hh:mm a"
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label htmlFor="numberOfPersons" className="block text-gray-700 font-bold mb-2">
                  Number of Persons
                </label>
                <input
                  type="number"
                  id="numberOfPersons"
                  name="numberOfPersons"
                  value={formData.numberOfPersons}
                  onChange={handleChange}
                  required
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
              {errorMessage && (
                <div className="text-red-500 text-md mb-4">
                  {errorMessage}
                </div>
              )}
              {successMessage && (
                <div className="text-green-500 text-md mb-4">
                  {successMessage}
                </div>
              )}
              <div className="flex justify-center lg:justify-start w-full">
                <button
                  type="submit"
                  className="px-6 py-3 mt-4 border-2 hover:bg-white bg-[#EEBC1D] border-[#EEBC1D] text-black text-lg font-semibold rounded-md transition duration-300 hover:bg-[#EEBC1D] hover:text-black"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div className="flex items-center justify-center">
            <div className="text-center">
              <h3 className="text-2xl md:text-3xl font-bold mb-4">Get in Touch</h3>
              <p className="text-lg md:text-xl text-gray-600 mb-2">Evanthe hotels</p>
              <p className="text-lg md:text-xl text-gray-600 mb-2"> Dlf Mypad, Tower B2 - 830, Vibhuti khand, Gomtinagar, Lucknow, Uttar Pradesh, 226010</p>
              <p className="text-lg md:text-xl text-gray-600 mb-2">Email: booking.evanthehotels@gmail.com</p>
              <p className="text-lg md:text-xl text-gray-600">Phone: +91 9151999569</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
