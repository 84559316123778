import React, { useState, useEffect } from 'react';

const Hero = () => {
  const images = [
    '/images/hero_1.jpeg',
    '/images/hero_2.jpeg',
    '/images/hero_3.jpeg',
  ];

  const [ currentImage, setCurrentImage ] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [ images.length ]);

  const scrollToSection = () => {
    const contactSection = document.getElementById('about');
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToContact = () => {
    const nextSection = document.getElementById('contact');
    if (nextSection) {
      nextSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="relative w-full h-screen overflow-hidden">
      {images.map((image, index) => (
        <div
          key={index}
          className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${index === currentImage ? 'opacity-100' : 'opacity-0'
            }`}
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            animation: index === currentImage ? 'zoomIn 15s infinite' : '',
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50" />
        </div>
      ))}
      <div className="absolute inset-0 flex flex-col items-center justify-center text-center text-white px-4 space-y-4">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold tracking-wide">
          Delux Studio in Gomtinagar
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl max-w-2xl">
          Experience luxury and comfort in every stay. Relax, recharge, and explore with us.
        </p>
        <button onClick={scrollToContact} className="px-6 py-3 mt-4 border-2 border-[#EEBC1D] text-black text-lg font-semibold rounded-md transition duration-300 hover:bg-transparent bg-[#EEBC1D] hover:text-black">
          Book Now
        </button>
        <div
          onClick={scrollToSection}
          className="absolute bottom-10 left-1/2.5 transform -translate-x-1/2 cursor-pointer animate-bounce"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-10 h-10 text-[#EEBC1D]"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 9l-7 7-7-7"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M19 5l-7 7-7-7"
            />
          </svg>
        </div>
      </div>

      <style>{`
        @keyframes zoomIn {
          0% {
            transform: scale(1);
          }
          100% {
            transform: scale(1.1);
          }
        }
      `}</style>


    </section>
  );
};

export default Hero;
