import React from 'react';
import { FaUser } from "react-icons/fa";

const Testimonials = () => {
  const testimonials = [
    {
      name: "Rajesh Kumar",
      feedback: "Staying here was like a home away from home. The hospitality was unmatched, and every request was taken care of with such warmth. Truly felt like a royal experience! Highly recommended for anyone visiting the city.",
      type: "Happy Customer"
    },
    {
      name: "Anjali Mehta",
      feedback: "An amazing experience! The staff was so courteous, and the amenities were top-notch. The rooms were super comfortable, and the food was just like maa ke haath ka khaana. Perfect for families looking for a comfortable stay.",
      type: "Delighted Guest"

    },
    {
      name: "Vikram Singh",
      feedback: "From the rooms to the service, everything was excellent. They truly went above and beyond to make our stay memorable. It’s hard to find such a friendly and welcoming place nowadays. I’m definitely coming back with my family!",
      type: "Loyal Customer"
    },
  ];
  return (
    <section className="py-20 bg-gray-100" style={{ backgroundImage: 'url(images/pattern.png)' }}>
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-10">What Our Customers Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {
            testimonials.map((testimonial: any, index: number) => <div key={index} className="bg-white rounded-lg shadow-lg p-6">
              <p className="text-gray-600 text-lg">{testimonial.feedback}</p>
              <div className="flex items-center mt-4">
                <FaUser className="w-10 h-10 rounded-full mr-4 text-gray-500" />
                <div>
                  <h3 className="text-xl font-bold">{testimonial.name}</h3>
                  <p className="text-gray-500">Happy Customer</p>
                </div>
              </div>
            </div>)
          }
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
